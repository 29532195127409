import React from "react"
import { useStaticQuery, graphql } from "gatsby";

import SEO from "../components/seo"

import LayoutHome from "../layouts/homepage"
import Main from "../components/main"
import PageHeader from "../components/chrome/pageHeader"
import PageFooter from "../components/chrome/pageFooter"
import Postteaser from "../components/post-teaser"
import Container from "../layouts/container"

import Breadcrumbs from "../components/chrome/breadcrumbs"

// eslint-disable-next-line no-unused-vars
import Styles from '../styles/styles.scss'

const PageIndex = () => {
  const data = useStaticQuery(graphql`{

  posts: allMarkdownRemark(
    filter: {fileAbsolutePath: {regex: "/(content/posts/)/"}}
    sort: {fields: [frontmatter___date], order: DESC}
  ) {
    totalCount
    edges {
      node {
        id
        frontmatter {
          title
          subtitle
          date
          dateModified
          datePublished
          tags
        }
        fields {
          slug
        }
        excerpt
      }
    }
  }
  references: allMarkdownRemark(
    filter: {fileAbsolutePath: {regex: "/(content/references/)/"}}
    sort: {fields: [frontmatter___date], order: DESC}
  ) {
    totalCount
    edges {
      node {
        id
        frontmatter {
          title
          subtitle
          date
          dateModified
          datePublished
          tags
        }
        fields {
          slug
        }
        excerpt
      }
    }
  }
    caseStudies: allMarkdownRemark(
    filter: {fileAbsolutePath: {regex: "/(content/case-studies/)/"}}
    sort: {fields: [frontmatter___date], order: DESC}
  ) {
    totalCount
    edges {
      node {
        id
        frontmatter {
          title
          subtitle
          date
          dateModified
          datePublished
          tags
        }
        fields {
          slug
        }
        excerpt
      }
    }
  }
}
`);

  return (
    <LayoutHome>
      <SEO title="Welcome" />
      <PageHeader headerText="Per ardua ad astra" />

      <Main>

        <Breadcrumbs title="Home" path='/' />

        <div className="row">
          <div className="col--full">
            <Container>

              <Container>
                <h1 className="h1">Latest entry in Posts</h1>
                <Postteaser post={data.posts.edges[0].node} key={data.posts.edges[0].id} />
              </Container>

              <Container>
                <h1 className="h1">Latest entry in References</h1>
                <Postteaser post={data.references.edges[0].node} key={data.references.edges[0].id} />
              </Container>

              <Container>
                <h1 className="h1">Latest entry in Case studies</h1>
                <Postteaser post={data.caseStudies.edges[0].node} key={data.caseStudies.edges[0].id} />
              </Container>

            </Container>
          </div>
        </div>
      </Main>

      <PageFooter />
    </LayoutHome>
  )
}

export default PageIndex